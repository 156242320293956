import React from 'react'; 
import FlipCoin from './components/FlipCoin'
import './App.css';

const App=()=> { 
return ( 
	<div className="App"> 
	<FlipCoin /> 
	</div>
); 
} 

export default App;
