import React, { Component } from 'react';
import Coin from './Coin';
import './Style.css';
import headImage from '../assets/image/heads.png';
import tailImage from '../assets/image/tails.png';
import twtImage from '../assets/image/twitter.webp';
import teleImage from '../assets/image/telegram.webp';

import loadingImage from '../assets/image/loading.gif'; 
import catflipGif from '../assets/image/catflip.gif'; 

class FlipCoin extends Component {
    static defaultProps = {
        coins: [
            { 
                side: 'head', 
                imgSrc: headImage
            },
            { 
                side: 'tail', 
                imgSrc: tailImage
            }
        ]
    }

    constructor(props) {
        super(props);
        this.state = {
            currFace: null,
            totalFlips: 0,
            heads: 0,
            resultMessage: "",
            isFlipping: false,
            balance: 100, // Initial point
            betAmount: 10, // Initial bet amount in point
            leaderboard: [],
            walletAddress: null,
            selectedOption: null, // State to store selected option (head or tail)
            isConnected: false,
            selectedBetAmount: 10 // Default selected bet amount
        };
        this.handleChoice = this.handleChoice.bind(this);
        this.handleBetChange = this.handleBetChange.bind(this);
        this.updateLeaderboard = this.updateLeaderboard.bind(this);
        this.connectWallet = this.connectWallet.bind(this);
    }

    componentDidMount() {
        // Generate a random player ID for demonstration (you should replace this with actual logic)
        const playerId = Math.random().toString(36).substring(7);
        this.setState({ playerId });
        this.loadLeaderboard(); // Load leaderboard data from storage or database

        // Check if already connected
        if (window.solana && window.solana.isConnected) {
            this.setState({ isConnected: true, walletAddress: window.solana.publicKey.toString() });
        }
    }

    choice(arr) {
        const randomIdx = Math.floor(Math.random() * arr.length);
        return arr[randomIdx];
    }

    async flipCoin(userChoice) {
        this.setState({ isFlipping: true, resultMessage: "" });

        setTimeout(() => {
            const newFace = this.choice(this.props.coins);
            this.setState(curState => {
                const heads = curState.heads + (newFace.side === 'head' ? 1 : 0);
                const win = userChoice === newFace.side;
                const newBalance = win ? curState.balance + curState.betAmount : curState.balance - curState.betAmount;
                const message = win ? 
                    `You won! The coin landed on ${newFace.side}. Your point now ${newBalance}.` : 
                    `You lost! The coin landed on ${newFace.side}. Your point now ${newBalance}.`;

                // Update leaderboard if player wins
                if (win) {
                    this.updateLeaderboard(curState.playerId, newBalance);
                }

                return { 
                    currFace: newFace,
                    totalFlips: curState.totalFlips + 1,
                    heads: heads,
                    resultMessage: message,
                    isFlipping: false,
                    balance: newBalance
                };
            });
        }, 2000);
    }

    handleChoice(choice) {
        if (!this.state.isConnected) {
            alert("Please connect your wallet first.");
            return;
        }

        if (this.state.balance >= this.state.betAmount) {
            this.setState({ selectedOption: choice }); // Set selected option when button clicked
            this.flipCoin(choice);
        } else {
            alert("Insufficient balance to place the bet.");
        }
    }

    handleBetChange(amount) {
        this.setState({ betAmount: amount, selectedBetAmount: amount });
    }

    loadLeaderboard() {
        // Load leaderboard from storage or database
        // For demonstration, use localStorage
        const leaderboard = JSON.parse(localStorage.getItem('leaderboard')) || [];
        this.setState({ leaderboard });
    }

    saveLeaderboard(leaderboard) {
        // Save leaderboard to storage or database
        // For demonstration, use localStorage
        localStorage.setItem('leaderboard', JSON.stringify(leaderboard));
    }

    updateLeaderboard(playerId, balance) {
        const { leaderboard } = this.state;
        const existingEntryIndex = leaderboard.findIndex(entry => entry.playerId === playerId);

        if (existingEntryIndex !== -1) {
            // Update existing entry
            leaderboard[existingEntryIndex].balance = balance;
        } else {
            // Add new entry
            leaderboard.push({ playerId, balance });
        }

        // Sort leaderboard by balance descending and keep top 5 entries
        leaderboard.sort((a, b) => b.balance - a.balance);
        const updatedLeaderboard = leaderboard.slice(0, 5);

        // Save updated leaderboard
        this.saveLeaderboard(updatedLeaderboard);

        // Update state
        this.setState({ leaderboard: updatedLeaderboard });
    }

    connectWallet() {
        // Implement connection to Solana wallet using Phantom
        // Example:
        if (window.solana && window.solana.isPhantom) {
            window.solana.connect();
            window.solana.on('connect', () => {
                this.setState({ isConnected: true, walletAddress: window.solana.publicKey.toString() });
            });
            window.solana.on('disconnect', () => {
                this.setState({ isConnected: false, walletAddress: null });
            });
        } else {
            alert("Phantom wallet extension is not installed.");
        }
    }

    render() {
        const { currFace, totalFlips, heads, resultMessage, isFlipping, balance, betAmount, leaderboard, walletAddress, isConnected, selectedOption, selectedBetAmount } = this.state;
        return (
            <div className="flip-coin-container">
                {!isConnected ? (
                    <div className="connect-wallet-info">
                        <button className="connect-wallet-btn" onClick={this.connectWallet}>Connect your Wallet here to convert your Point soon!</button>
                    </div>
                ) : (
                    <div className="connect-wallet-info">
                        <p>Connected Address:</p>
                        <p>{walletAddress}</p>
                    </div>
                )}
                {!isFlipping && !currFace && (
                    <div className="flip-btn">Cat Flip</div>
                )}
                <div className="img-coin">
                    {isFlipping ? (
                        <div className="flipping">
                            <img src={loadingImage} alt="Loading..." />
                        </div>
                    ) : (
                        currFace ? (
                            <Coin info={currFace} />
                        ) : (
                            <img src={catflipGif} className="coin-gif" alt="CatFlip" />
                        )
                    )}
                </div>
                <div className="balance-section">
                    <p className="balance-text">Points: {balance}</p>
                    <div className="bet-section">
                        <p>Bet Amount:</p>
                        <div className="button-container">
                            <button className={selectedBetAmount === 10 ? "selected" : ""} onClick={() => this.handleBetChange(10)} disabled={isFlipping}>10 Points</button>
                            <button className={selectedBetAmount === 20 ? "selected" : ""} onClick={() => this.handleBetChange(20)} disabled={isFlipping}>20 Points</button>
                            <button className={selectedBetAmount === 50 ? "selected" : ""} onClick={() => this.handleBetChange(50)} disabled={isFlipping}>50 Points</button>
                            <button className={selectedBetAmount === 100 ? "selected" : ""} onClick={() => this.handleBetChange(100)} disabled={isFlipping}>100 Points</button>
                        </div>
                    </div>
                </div>
                <div className='btn-play-txt'>Pick:</div>
                <div className="btn-game-container">
                    <button className={`btn-game ${selectedOption === 'head' ? 'selectedd' : ''}`} onClick={() => this.handleChoice('head')} disabled={!isConnected || isFlipping || betAmount > balance}><p>Head</p></button>
                    <button className={`btn-game ${selectedOption === 'tail' ? 'selectedd' : ''}`} onClick={() => this.handleChoice('tail')} disabled={!isConnected || isFlipping || betAmount > balance}><p>Tail</p></button>
                </div>
                <div className="game-txt">
                    {resultMessage}
                </div>
                <div className="leaderboard">
                    <h3>Leaderboard</h3>
                    <ul className="leaderboard-list">
                        {leaderboard.map((entry, index) => (
                            <li key={index} className="leaderboard-item">{entry.playerId}: {entry.balance} Points</li>
                        ))}
                    </ul>
                </div>
                <div className='socials-txt'>
                    Socials
                </div>
                <div className="txt-info">
                    <a href="https://x.com/cicicat_" target="_blank"><img src={twtImage} className='icon-socials' alt='twitter'/></a>
                    <a href="https://t.me/cicicatportall" target="_blank"><img src={teleImage} className="icon-socials" alt='telegram'/></a>
                    
                </div>
                <div className="footer">© 2024 CatFlip - All Rights Reserved.</div>
            </div>
        );
    }
}

export default FlipCoin;
